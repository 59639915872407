<template>
  <main class="e-chat">
    <header class="e-chat__header">
      <div
        :class="[
          'e-chat__title',
          {
            'chat-is-pending':
              currentUserChat && currentUserChat.status === 'pending'
          }
        ]"
      >
        {{ chatTitle }}
      </div>
      <el-tooltip
        v-if="currentUserChat"
        :content="
          currentUserChat.status === 'active'
            ? $t('chat.close')
            : $t('chat.cancel')
        "
        class="item"
        effect="dark"
        placement="top"
      >
        <icon-close class="e-chat__close" @click="finishChat" />
      </el-tooltip>
    </header>

    <div class="e-chat__content">
      <VuePerfectScrollbar ref="scrollContainer">
        <div class="e-chat__content-inner">
          <chat-item
            v-for="(msg, index) in messages"
            :key="index"
            :type="msg.type"
            :messages="msg.messages"
          />
        </div>
      </VuePerfectScrollbar>
      <div
        class="e-chat__scroll-to-message e-btn e-btn--primary"
        :class="{ 'e-chat__scroll-to-message--visible': notSeenMessage }"
        @click="
          () => {
            if (!notSeenMessage) {
              return
            }
            scrollToBottom()
          }
        "
      >
        <div class="e-chat__scroll-to-message--icon">
          <icon-arrow-down />
        </div>
      </div>
      <the-chat-typing-indicator
        v-if="currentUserChat && typingState && !hideTypingState"
        :text="`${currentUserChat.counselor}  schreibt`"
      />
    </div>

    <footer v-show="!isDisabled" class="e-chat__footer">
      <autosize-textarea
        v-model="chatTextMessage"
        :placeholder="$t('chat.inputPlaceholder')"
        name="chatTextMessage"
        cols="10"
        rows="1"
        class="e-chat__input"
        @input.native="ev => (charCount = chatTextMessage.length)"
        @keydown.native="startTypingStatus"
        @keydown.enter.native="handleKeyupEnter"
      />
      <div class="e-chat__submit">
        <button
          :disabled="!canSend"
          :class="{ 'is-disabled': !canSend }"
          type="submit"
          class="e-btn e-btn--primary"
          @click.prevent="submitMessage"
        >
          {{ $t('chat.submit') }}
        </button>
      </div>
      <div
        :class="{
          'e-dialog__charcount--alert': maxCharsExceeded
        }"
        class="e-dialog__charcount"
      >
        {{ charCountString }}
      </div>
    </footer>
  </main>
</template>

<script>
import debounce from 'lodash/debounce'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions, mapGetters } from 'vuex'

import api from 'api'
import { mercureHubUrl } from 'api/config'
import ChatItem from 'molecules/ChatItem/ChatItem'
import TheChatTypingIndicator from 'atoms/TheChatTypingIndicator/TheChatTypingIndicator'
import AutosizeTextarea from '../../../shared/AutosizeTextarea'
import IconArrowDown from 'assets/icons/arrow-down.svg'

import IconClose from 'assets/icons/close.svg'

export default {
  /** ========================================== */
  /*     COMPONENTS                              */
  /** ========================================== */
  components: {
    AutosizeTextarea,
    ChatItem,
    IconClose,
    VuePerfectScrollbar,
    TheChatTypingIndicator,
    IconArrowDown
  },

  props: {
    propMessages: {
      type: Array,
      default: () => [],
      required: false
    },
    propId: {
      type: String,
      default: null,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    currentUserChat: {
      type: Object
    },
    chatTitle: {
      type: String
    },
    hasActiveChat: {
      type: Boolean
    },
    showClosedMessage: {
      type: Boolean
    }
  },
  /** ========================================== */
  /*     DATA                                    */
  /** ========================================== */
  data: () => ({
    chatTextMessage: '',
    chatData: {
      id: null,
      messages: []
    },
    charCount: 0,
    maxChars: 500,
    hideTypingState: false,
    typingTimer: null,
    oponentTypingTimer: null,
    sendingMessage: false,
    readingClosedMessage: false,
    notSeenMessage: false
  }),

  /** ========================================== */
  /*     COMPUTED                                */
  /** ========================================== */
  computed: {
    ...mapGetters(['userInfo', 'typingState']),

    charCountString() {
      return `${this.charCount} / ${this.maxChars}`
    },

    maxCharsExceeded() {
      return this.charCount > this.maxChars
    },

    canSend() {
      if (
        this.maxCharsExceeded ||
        this.charCount === 0 ||
        this.disabled ||
        this.sendingMessage
      ) {
        return false
      }
      // if (this.currentUserChat && this.currentUserChat.status === 'pending' && this.currentUserChat.messages.find((message) => !message.isDeleted) !== undefined) {
      //   return false
      // }
      return true
    },

    messages() {
      if (this.userInfo === undefined) {
        return []
      }
      let messages = this.chatData.messages
      if (this.hasActiveChat && this.currentUserChat.messages.length > 0) {
        messages = this.currentUserChat.messages
      }

      const output = []
      messages.forEach((msg, i) => {
        if (i > 0 && output[output.length - 1].userId === msg.userId) {
          output[output.length - 1].messages.push({
            text: msg.body,
            id: msg.id,
            deleted: msg.isDeleted,
            createdAt: msg.createdAt
          })
        } else {
          output.push({
            type: msg.userId !== this.userInfo.id ? 'opponent' : 'user',
            userId: msg.userId,
            messages: [
              {
                text: msg.body,
                id: msg.id,
                deleted: msg.isDeleted,
                createdAt: msg.createdAt
              }
            ]
          })
        }
      })

      if (this.currentUserChat && this.currentUserChat.status === 'pending') {
        output.push({
          type: 'opponent',
          userId: null,
          messages: [
            {
              text: window.landingTexts.chatWaitingMessage,
              createdAt: new Date()
            }
          ]
        })
      }

      return output
    }
  },

  watch: {
    showClosedMessage(newVal) {
      this.handleClosedMessage(newVal)
    },
    typingState(typingState) {
      if (typingState) {
        this.hideTypingState = false
        clearTimeout(this.oponentTypingTimer)
        this.oponentTypingTimer = setTimeout(() => {
          this.hideTypingState = true
          this.oponentTypingTimer = null
        }, 30000)
      }
    },
    messages: {
      handler: function(newVal) {
        if (!newVal.length) {
          return
        }
        const lastMessageGroup = newVal[newVal.length - 1]
        if (lastMessageGroup.type === 'opponent') {
          setTimeout(() => {
            this.notSeenMessage = true
            let options = {
              root: this.$el.querySelector('.e-chat'),
              rootMargin: '0px',
              threshold: 0.5
            }

            let callback = (entries, observer) => {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  this.notSeenMessage = false
                  observer.unobserve(target)
                }
              })
            }
            let observer = new IntersectionObserver(callback, options)
            let target = this.$el.querySelector(
              '.e-chat__item:last-of-type .e-chat__message:last-of-type'
            )
            observer.observe(target)
          }, 50)
        } else {
          this.scrollToBottom()
        }
      }
    }
  },
  /** ========================================== */
  /*     MOUNTED                                 */
  /** ========================================== */
  mounted() {
    this.scrollToBottom()
    if (this.propId) {
      this.chatData.id = this.propId
      this.chatData.messages = this.propMessages
      return
    }
    if (!this.hasActiveChat) {
      this.sendWelcomeMessage()
    }

    if (this.showClosedMessage) {
      this.handleClosedMessage(true)
    }
  },

  beforeDestroy() {
    clearTimeout(this.typingTimer)
    this.endTypingStatus()
  },

  /** ========================================== */
  /*     METHODS                                 */
  /** ========================================== */
  methods: {
    ...mapActions(['setInterruptedRoute', 'startCurrentUserChat']),
    async submitMessage() {
      if (!this.canSend) {
        return
      }
      this.sendingMessage = true
      const timestamp = new Date()
      let msg = {
        id: null,
        userId: this.userInfo.id,
        body: this.chatTextMessage,
        createdAt: timestamp
      }
      let isStart = this.currentUserChat === null

      const response = await api
        .call(
          'sendChatMessage',
          this.hasActiveChat ? this.currentUserChat.id : null,
          msg
        )
        .finally(() => {
          this.sendingMessage = false
        })

      if (response && response.data) {
        this.startCurrentUserChat(response.data.id)
      }

      if (isStart || this.currentUserChat.status === 'pending') {
        this.addMessage(msg)
      }

      this.chatTextMessage = ''
      this.charCount = 0
    },

    handleKeyupEnter(ev) {
      if ((ev.metaKey || ev.ctrlKey) && ev.keyCode === 13) {
        this.submitMessage()
      }
    },

    addMessage(msg) {
      this.chatData.messages.push(msg)
    },

    scrollToBottom() {
      if (this.$refs.scrollContainer) {
        this.$nextTick(() => {
          this.$refs.scrollContainer.$el.scrollTop = this.$refs.scrollContainer.$el.scrollHeight
        })
      }
    },

    sendWelcomeMessage() {
      this.addMessage({
        id: null,
        body: this.$t('chat.welcomeMessage'),
        userId: null,
        createdAt: new Date()
      })
    },

    async finishChat() {
      if (this.currentUserChat.status === 'active') {
        this.$confirm(
          this.$t('chat.activeClosingConfirm'),
          this.$t('chat.close'),
          {
            confirmButtonText: this.$t('system.confirm'),
            cancelButtonText: this.$t('system.cancel'),
            type: 'warning'
          }
        ).then(async () => {
          api.call('closeChat', this.currentUserChat.id)
          this.$emit('close-chat')
        })
        return
      }
      this.$confirm(
        this.$t('chat.pendingClosingConfirm'),
        this.$t('chat.cancel'),
        {
          confirmButtonText: this.$t('system.confirm'),
          cancelButtonText: this.$t('system.cancel'),
          type: 'warning'
        }
      ).then(async () => {
        api.call('cancelChat', this.currentUserChat.id)
        this.$emit('cancel-chat')
      })
    },

    startTypingStatus: debounce(
      function() {
        if (this.currentUserChat && this.currentUserChat.status === 'active') {
          if (!this.typingTimer) {
            const body = new URLSearchParams({
              data: JSON.stringify({ userId: this.userInfo.id, value: true }),
              topic: `http://mercure.local/currentChat/${this.currentUserChat.id}`,
              private: 'on',
              type: 'typingState'
            })
            fetch(mercureHubUrl, {
              method: 'POST',
              mode: 'cors',
              body,
              credentials: 'include'
            })
          }
          clearTimeout(this.typingTimer)
          this.typingTimer = setTimeout(() => {
            this.typingTimer = null
            this.endTypingStatus()
          }, 1000)
        }
      },
      100,
      { leading: true, trailing: false }
    ),

    endTypingStatus: debounce(
      function() {
        if (this.currentUserChat && this.currentUserChat.status === 'active') {
          const body = new URLSearchParams({
            data: JSON.stringify({ userId: this.userInfo.id, value: false }),
            topic: `http://mercure.local/currentChat/${this.currentUserChat.id}`,
            private: 'on',
            type: 'typingState'
          })
          fetch(mercureHubUrl, {
            method: 'POST',
            mode: 'cors',
            body,
            credentials: 'include'
          })
        }
      },
      100,
      { leading: false, trailing: true }
    ),
    handleClosedMessage(show) {
      if (show && !this.readingClosedMessage) {
        this.readingClosedMessage = true
        this.$alert(this.$t('chat.closedByCounselor'), this.$t('chat.closed'), {
          confirmButtonText: this.$t('system.ok')
        }).finally(() => {
          api.call('closedChatInfoRead', this.currentUserChat.id)
          this.readingClosedMessage = true
          this.$emit('closed-chat-info-read')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/TheChat';
</style>
